import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Options } from '../../../interfaces/options.js';
import { Room } from '../../../interfaces/room';
import { AuthService, BroadcastService, RoomsService } from '../../../services';
// import { register } from 'extendable-media-recorder';
// import { connect } from 'extendable-media-recorder-wav-encoder';
// import { Options } from 'src/app/interfaces/options';
import { ExportService } from '../../../services/export.service';
import VADBuilder, { VADMode, VADEvent } from "../../../../assets/vad_wasm/embedded.js";
// import { AudioBufferService } from '../../../services/audio';
declare var require: any;

const {
  RevoiceActivationRequest,
  RevoiceStreamingRequest,
  Agent,
  SwitchingRequest,
  AcceptSwitchingRequest,
  Status
} = require('../../../../assets/proto_v3/streaming_pb.js');
const {
  RevoiceStreamingClient,
} = require('../../../../assets/proto_v3/streaming_grpc_web_pb.js');
// declare const VAD: any;
var VAD = require('@mjyc/voice-activity-detection');

@Component({
  selector: 'app-revoice',
  templateUrl: './revoice.component.html',
  styleUrls: ['../revoice.component.css']
})
export class RevoiceComponent implements OnInit, OnDestroy {
  room: Room;
  connectivity;
  micOpen: boolean = true;

  actionConnectWebRTC = false;
  onlineAgents: any = []

  wsSubscription: Subscription;

  profile;

  statusAgnet = ['ACTIVE', 'WAITING', 'BUSY', 'OFFLINE']
  activeAgent: any;

  statusConnect = 'connecting';

  statusRequest = 'none'
  timeoutSwitcth = 10;
  interval;
  timeOutReconnect;

  isActive: boolean = false;
  source: any;
  context: any;
  localStream: any = null;
  recorder: any;
  vad: any;

  client: any;

  resultTranscribe: boolean = true;
  resultRevoice: string = '';
  countResultRevoice = 0;
  previousDataByteAudio: any[] = [];
  countActive = 0
  requester: number = 0;


  isStartVAD: boolean = false;
  streamAt: number = 0

  keyToTalk: any = {
    key: 'g',
    code: 'KeyG'
  };
  tmpKeyToTalk: any = {
    key: 'g',
    code: 'KeyG'
  };

  intervalCountActiveTime;
  isRecordKeyBind = false;

  counterThresh: number = 3;
  counterMax: number = 8;
  minNoise: number = 0.1;

  warningCountActive = 5;
  dangerCountActive = 10

  chunkId = 0;
  frameCount = 0;

  previousStatus = 'OFFLINE'

  soundStream: boolean = true;
  streamMode: string = 'video';

  active_audio = false;

  VADMode: any = VADMode.NORMAL;
  VADDurationThreshold: number = 5;

  // Padding function variables declaration
  public paddingBuffer = new Float32Array()
  public paddingSize: number // sample rate * padding duration(second)
  public paddingDuration = 0.2

  // public audioBufferServiceNoPadding = new AudioBufferService()
  // public audioBufferServicePadding = new AudioBufferService()
  // public audioBufferAll = new AudioBufferService()

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private roomService: RoomsService,
    private bcService: BroadcastService,
    private exportService: ExportService) { }

  ngOnDestroy() {
    if (this.timeOutReconnect) {
      clearTimeout(this.timeOutReconnect);
    }
    if (this.wsSubscription) {
      this.wsSubscription.unsubscribe();
    }
    if (this.localStream) {
      this.localStream.getTracks()[0].stop();
    }
    if (this.recorder) {
      this.recorder.disconnect(this.context.destination)
    }
    if (this.source) {
      this.source.disconnect(this.recorder);
    }
    if (this.statusConnect == 'connected') {
      this.deActive();
      // if(this.checkCurrentStatus() == 'ACTIVE') {
      //   this.insertBufferVoiceToList()
      // }
    }

    // save all buffer to wav file
    const dateTimestamp = new Date().getTime()
    // this.audioBufferAll.saveToWavFile(`audio_${dateTimestamp}_all.wav`)
  }

  // async canDeactivate() {
  //   // if(this.statusConnect == 'connected') {
  //   //   if(this.checkCurrentStatus() == 'ACTIVE') {
  //   //     this.insertBufferVoiceToList()
  //   //   } 

  //   //   if(this.listBufferVoice.length != 0) {
  //   //     var value = confirm("ต้องการดาวน์โหลดไฟล์เสียงที่ VAD จับเสียงหรือไม่?")
  //   //     if(value) {

  //   //       var filename: string = `${this.room.id}_${this.profile.firstname}`
  //   //       return await this.exportService.exportVoiceClient(this.listBufferVoice, filename)
  //   //     } else {
  //   //       return true;
  //   //     }
  //   //   } 
  //   //   return true
  //   // }

  //   return true
  // }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event): void {
    this.deActive()

  }

  deActive() {
    var agent = new Agent()
    agent.setClientId(this.profile.id)
    this.client.deactivate(agent, {}, (err: any, response: any) => {
      window.location.reload();
    });

  }

  startCountActive() {
    this.intervalCountActiveTime = setInterval(() => {
      this.countActive += 1;
      (<HTMLSpanElement>document.getElementById("countActive")).innerHTML = `เวลาพูด ${this.countActive} วินาที`
      if (this.countActive == this.warningCountActive) {
        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-active')
        document.getElementById('voiceDetectIcon').classList.add('voice-detect-warning')
      } else if (this.countActive == this.dangerCountActive) {
        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-warning')
        document.getElementById('voiceDetectIcon').classList.add('voice-detect-danger')
      }
    }, 1000)

  }




  stopCountActive() {
    this.countActive = 0;
    (<HTMLSpanElement>document.getElementById("countActive")).innerHTML = `เวลาพูด ${this.countActive} วินาที`
    clearInterval(this.intervalCountActiveTime)

  }
  ngOnInit(): void {
    this.profile = this.authService.getUser();
    this.intitialRoom();
  }

  back() {
    this.router.navigate(['rooms']);
  }

  intitialRoom() {
    this.activeRoute.params.subscribe(param => {
      this.roomService.getRoomById(param['roomId']).subscribe((items: Room) => {
        this.room = items;
        if (this.room.options.find(option => option.type === "VAD_PADDING_DURATION")) {
          this.paddingDuration = parseInt(this.room.options.find(option => option.type === "VAD_PADDING_DURATION").value) / 1000
        }
        this.paddingSize = 16000 * this.paddingDuration
        this.connectivity = items.connectivity;
        this.settingVAD();
        setTimeout(() => {
          this.connectEnvoy();

        }, 3000)
      });
    });
  }

  settingVAD() {
    var vadMode: Options = this.room.options.find((e: Options) => e.type == 'VAD_MODE');
    var vadDurationThreshold: Options = this.room.options.find((e: Options) => e.type == 'VAD_DURATION_THRESHOLD');
    if (vadMode) {
      switch (vadMode.description) {
        case "NORMAL":
          this.VADMode = VADMode.NORMAL
          break;
        case "LOW_BITRATE":
          this.VADMode = VADMode.LOW_BITRATE
          break;
        case "AGGRESSIVE":
          this.VADMode = VADMode.AGGRESSIVE
          break;
        case "VERY_AGGRESSIVE":
          this.VADMode = VADMode.VERY_AGGRESSIVE
          break;
        default:
          break;
      }
    }

    if (vadDurationThreshold) {
      this.VADDurationThreshold = parseInt(vadDurationThreshold.description)
      this.warningCountActive = this.VADDurationThreshold
      this.dangerCountActive = this.VADDurationThreshold * 2
    }

    //   var thresh:Options = this.room.options.find((e:Options) => e.type == 'VAD_COUNTER_THRESH');
    //   var max:Options  = this.room.options.find((e:Options) => e.type == 'VAD_COUNTER_MAX');
    //   var noise:Options = this.room.options.find((e:Options) => e.type == 'VAD_MIN_NOISE');
    //   var warning:Options = this.room.options.find((e:Options) => e.type == 'WARNING_ACTIVE_VAD');
    //   var danger:Options = this.room.options.find((e:Options) => e.type == 'DANGER_ACTIVE_VAD');
    //   if(thresh) {
    //     this.counterThresh = parseInt(thresh.description);
    //   }
    //   if(max) {
    //     this.counterMax = parseInt(max.description);
    //   }
    //   if(noise) {
    //     this.minNoise = parseInt(noise.description);
    //   }
    //   if(warning) {
    //     this.warningCountActive = parseInt(warning.description)
    //   }
    //   if(danger) {
    //     this.dangerCountActive = parseInt(danger.description)
    //   }
  }

  // insertBufferVoiceToList() {
  //   if(this.bufferVoice.length != 0) {
  //     this.listBufferVoice.push(this.bufferVoice);
  //     this.bufferVoice = [];
  //   }

  // }

  connectVAD() {
    if (!this.isStartVAD) {
      var options = {
        useNoiseCapture: false,
        activityCounterThresh: this.counterThresh,
        activityCounterMax: this.counterMax,
        minNoiseLevel: this.minNoise,
        onVoiceStart: () => {
          document.getElementById('voiceDetectIcon').classList.remove('voice-detect-not-active')
          document.getElementById('voiceDetectIcon').classList.add('voice-detect-active')
          this.isActive = true;
          this.startCountActive()
        },
        onVoiceStop: () => {
          this.stopCountActive()
          document.getElementById('voiceDetectIcon').classList.remove('voice-detect-danger')
          document.getElementById('voiceDetectIcon').classList.remove('voice-detect-warning')
          document.getElementById('voiceDetectIcon').classList.remove('voice-detect-active')
          document.getElementById('voiceDetectIcon').classList.add('voice-detect-not-active')
          if (this.isActive) {
            this.isActive = false;
            this.sendGrpc(2, new Float32Array());
          }
        },
        onUpdate: (val: any) => {
        }
      };
      this.vad = VAD(this.context, this.localStream, options)
      this.isStartVAD = true;
    }

  }

  connectEnvoy() {
    this.client = new RevoiceStreamingClient(`https://${this.room.connectivity.host}:${this.room.connectivity.queue_port}`);
    this.listenEvents();
    var activationRequest = new RevoiceActivationRequest();
    activationRequest.setClientId(this.profile.id);
    activationRequest.setStatus(1);

    this.client.activate(activationRequest, {}, (err: any, response: any) => {
      this.statusConnect = 'connected';
      console.log('activation callback', response.getStatus(), err);
      console.log(this.statusConnect);

    });
    // this.connectMediaStream();
    // this.startVADWasm()
    this.actionConnectWebRTC = true
  }

  async listenEvents() {
    let agent = new Agent();
    agent.setClientId(this.profile.id);

    var statusStream = this.client.listenClientStatus(agent, {});
    statusStream.on('data', (response: any) => {
      this.parseOnlineAgent(response.wrappers_[1].map_)

      if (this.previousStatus == 'ACTIVE' && this.previousStatus != this.checkCurrentStatus()) {

        // this.insertBufferVoiceToList()
      }
      this.previousStatus = this.checkCurrentStatus()

    });
    statusStream.on('error', (err: any) => {
      console.log('error', err);
    });

    var switchingResponsedStatus = this.client.listenSwitchingResponsedStatus(agent, {});
    switchingResponsedStatus.on('data', (response: any) => {
      if (response.getIsAccepted()) {
        this.playSwitchAgent()
      } else {
        this.playReject()
      }
      if (this.interval) {
        clearInterval(this.interval)
      }

      console.log('switchingResponsedStatus')
      this.statusRequest = 'none';
    });
    switchingResponsedStatus.on('error', (err: any) => {
      console.log('switchingResponsedStatus-error', err);
    });

    var switchingRequestedStatus = this.client.listenSwitchingRequestedStatus(agent, {});
    switchingRequestedStatus.on('data', (response: any) => {
      this.playSoundRequest()
      this.requester = response.getRequesterClientId();
      this.statusRequest = 'accept'
      this.interval = setInterval(() => {
        if (this.timeoutSwitcth > 0) {
          this.playCountBeep()
          this.timeoutSwitcth--;
        } else {
          this.acceptSwitchUser(false, 'TimeOut');
        }

      }, 1000)

    });
    switchingRequestedStatus.on('error', (err: any) => {
      console.log('switchingRequestedStatus-error', err);
    });
  }

  playCountBeep() {
    var audio = new Audio('../../../../assets/sound/timeout_beep.mp3')
    audio.play()
  }
  //  async connectMediaStream() {
  //     await register(await connect());

  //     let stream = null;

  //     try {
  //       stream = await navigator.mediaDevices.getUserMedia({
  //         audio: { sampleSize: 16, channelCount: 1 },
  //       });
  //       this.localStream = stream;

  //       this.context = new AudioContext({ sampleRate: 16000 });
  //       this.source = this.context.createMediaStreamSource(stream);

  //       // bufferSize: the onaudioprocess event is called when the buffer is full
  //       var bufferSize = 1024;
  //       var numberOfInputChannels = 1;
  //       var numberOfOutputChannels = 1;

  //       // this.recorder = context.createBuffer(1, 640, 16000); // 640 is 2 frames
  //       this.recorder = this.context.createScriptProcessor(
  //         bufferSize,
  //         numberOfInputChannels,
  //         numberOfOutputChannels
  //       );

  //       // start Record
  //       this.source.connect(this.recorder);
  //       this.recorder.connect(this.context.destination);



  //       this.recorder.onaudioprocess = (e: any) => {
  //         let data = e.inputBuffer.getChannelData(0);
  //         this.procressActiveVoice(data);
  //       };
  //       this.actiocConnectWebRTC = true
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }

  setBuffer(data: Float32Array) {
    this.previousDataByteAudio.push(new Float32Array(data));
    if (this.previousDataByteAudio.length > this.counterThresh) {
      this.previousDataByteAudio.shift();
    }

  }

  // procressActiveVoice(data: Float32Array) {
  //   if (this.isActive) {
  //     if (this.previousDataByteAudio.length != 0) {
  //       this.previousDataByteAudio.forEach((e: Float32Array) => {
  //         this.sendGrpc(1, e);
  //       });
  //       this.previousDataByteAudio = []; 

  //     }


  //     this.sendGrpc(1, data);
  //   } else {
  //     this.setBuffer(data);
  //     // this.pushBufferVoice();
  //     this.frameCount = 0
  //   }
  // }

  // pushBufferVoice() {
  //   if (this.voiceClient.length != 0) {
  //     this.bufferVoice.push(this.voiceClient);
  //     this.voiceClient = new Float32Array();
  //   }

  // }

  updateStatus(status) {
    var request = new Status();
    request.setClientId(this.profile.id);
    request.setStatus(status);
    this.client.updateStatus(request, {}, (err: any, response: any) => {
      console.log('updateStatus callback', response, err);
    });

  }


  sendGrpc(state: number, dataBytes: Float32Array, isForcedVad: boolean = false) {
    if (this.checkCurrentStatus() == 'ACTIVE') {
      // this.voiceClient = Float32Array.from([...this.voiceClient, ...dataBytes]);
      var request = new RevoiceStreamingRequest();
      request.setClientId(this.profile.id);
      request.setAudioList(dataBytes);
      request.setChunkId(this.chunkId)
      request.setFrameCount(this.frameCount)
      if (state == 2) {
        request.setEos(true);

      } else {
        request.setEos(false);
      }

      request.setIsForcedVad(isForcedVad)

      this.client.stream(request, {}, (err: any, response: any) => {
        // console.log(err);
        // console.log(response);
      });
      this.frameCount++;
    }

  }


  // Setting Options
  muteMic() {
    this.micOpen = false;
    this.updateStatus(2);

  }

  openMic() {
    this.micOpen = true;
    this.updateStatus(1);

  }

  switchUser(user_id?) {
    this.statusRequest = 'request'
    var request = new SwitchingRequest();
    request.setRequesterClientId(this.profile.id);
    request.setRequestedToClientId(user_id ? parseInt(user_id) : 0);
    this.client.requestSwitching(request, {}, (err: any, response: any) => {
      console.debug('requestSwitching callback', response, err);
    });

  }

  acceptSwitchUser(accept, reason?) {
    this.timeoutSwitcth = 10;
    if (this.interval) {
      clearInterval(this.interval)
    }

    this.statusRequest = 'none'

    var request = new AcceptSwitchingRequest();
    request.setRequesterClientId(this.requester);
    request.setRequestedToClientId(this.profile.id);
    request.setIsAccepted(accept);
    request.setReason(reason);

    this.client.acceptSwitching(request, {}, (err: any, response: any) => {
      // console.log('requestSwitching callback', response, err);
    });
  }

  parseOnlineAgent(object) {
    this.activeAgent = object;
    this.onlineAgents = Object.keys(object).map(e => {
      return {
        id: object[`${e}`].key,
        status: this.statusAgnet[object[`${e}`].value]
      }
    });
  }

  checkCurrentStatus() {
    if (this.statusConnect == 'connected') {
      if (this.activeAgent) {
        return this.statusAgnet[this.activeAgent[`${this.profile.id}`].value]
      }
    }
    return 'OFFLINE'
  }

  @HostListener('window:keydown', ['$event'])
  handlerKeyDown(event: KeyboardEvent): void { }

  @HostListener('window:keyup', ['$event'])
  handlerKeyUp(event: KeyboardEvent): void { }

  recordKeybind() {
    this.isRecordKeyBind = !this.isRecordKeyBind;
  }

  closeSetting() {
    this.tmpKeyToTalk = this.keyToTalk;
  }

  saveSetting() {
    this.keyToTalk = this.tmpKeyToTalk;
  }

  refreshSound() {

  }

  playSoundRequest() {
    (<HTMLAudioElement>document.getElementById('request')).play();
  }

  changeAudioMode() {
    this.streamMode = 'audio'
  }

  changeVideoMode() {
    this.streamMode = 'video';
  }


  async startVADWasm() {
    if (!this.isStartVAD) {
      const VAD = await VADBuilder();
      const vad = new VAD(this.VADMode, 16000);
      // const bufferSize = vad.getMinBufferSize(1024);  // Audio worklet pumps 'em in frames
      const bufferSize = 1024
      const worklet = `
		class AudioProc extends AudioWorkletProcessor {
			constructor() {
				super();
			}

			process(inputs, outputs, params) {
				this.port.postMessage(inputs[0][0]);
				return true;
			}
		}
		registerProcessor("audio-proc", AudioProc);`;

      navigator.mediaDevices.getUserMedia({ audio: { sampleSize: 16, channelCount: 1 }, video: false })
        .then((stream) => {
          const context = new AudioContext({ sampleRate: 16000 });
          const source = context.createMediaStreamSource(stream);

          const blob = new Blob([worklet], { type: "text/javascript" });
          const uri = URL.createObjectURL(blob);

          context.audioWorklet.addModule(uri).then(() => {
            const node = new AudioWorkletNode(context, "audio-proc");
            let buffer = new Int16Array(bufferSize);
            let offset = 0;
            let buffer32 = new Float32Array(bufferSize)
            node.port.onmessage = (event) => {

              const frame = VAD.floatTo16BitPCM(event.data);
              for (let i = 0; i < frame.length; i++) {
                if (offset < bufferSize) {
                  buffer[offset] = frame[i];
                  buffer32[offset] = event.data[i]
                  offset++;
                } else {
                  if (this.countActive == this.VADDurationThreshold) {
                    vad.setMode(VADMode.VERY_AGGRESSIVE)
                  }

                  var res = vad.processBuffer(buffer);
                  switch (res) {
                    case VADEvent.SILENCE:
                      
                      if (this.isActive) {
                        this.sendGrpc(2, buffer32);
                        // this.audioBufferServiceNoPadding.addToBuffer(buffer32)
                        // this.audioBufferServicePadding.addToBuffer(buffer32)
                        
                        // const dateTimestamp = new Date().getTime()
                        // this.audioBufferServiceNoPadding.saveToWavFile(`audio_${dateTimestamp}.wav`)
                        // this.audioBufferServiceNoPadding.clearBuffer()
                        // this.audioBufferServicePadding.saveToWavFile(`audio_${dateTimestamp}_padding.wav`)
                        // this.audioBufferServicePadding.clearBuffer()


                        this.chunkId++;
                        this.frameCount = 0;
                        this.stopCountActive()
                        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-danger')
                        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-warning')
                        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-active')
                        document.getElementById('voiceDetectIcon').classList.add('voice-detect-not-active')
                      }
                      this.isActive = false;
                      if (this.paddingSize > 0) {
                        this.paddingBuffer = new Float32Array([...this.paddingBuffer, ...buffer32])
                        // this.audioBufferAll.addToBuffer(buffer32)
                      }
                      break;
                    case VADEvent.VOICE:
                      if (!this.isActive) {
                        if (this.paddingSize > 0) {
                          // send previous audio as padding in the server
                          this.sendGrpc(1, this.paddingBuffer.slice(-this.paddingSize));
                          // this.audioBufferServicePadding.addToBuffer(this.paddingBuffer.slice(-this.paddingSize))

                          // clear padding buffer and keep the last paddingSize
                          this.paddingBuffer = this.paddingBuffer.slice(-this.paddingSize)
                        }

                        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-not-active')
                        document.getElementById('voiceDetectIcon').classList.add('voice-detect-active')
                        this.startCountActive()
                        vad.setMode(this.VADMode)
                      }

                      this.sendGrpc(1, buffer32);

                      if (this.paddingSize > 0) {
                        this.paddingBuffer = new Float32Array([...this.paddingBuffer, ...buffer32])
                        // this.audioBufferAll.addToBuffer(buffer32)
                        // this.audioBufferServiceNoPadding.addToBuffer(buffer32)
                        // this.audioBufferServicePadding.addToBuffer(buffer32)
                      }

                      this.isActive = true;

                      break;
                    case VADEvent.ERROR:

                      break;
                  }
                  offset = 0;
                  buffer = new Int16Array(bufferSize);
                  buffer32 = new Float32Array(bufferSize)
                  buffer[offset] = frame[i];
                  buffer32[offset] = event.data[i]


                }
              }
            };

            source.connect(node).connect(context.destination);
          });
        });
      this.isStartVAD = true;
    }

  }

  playSwitchAgent() {
    (<HTMLAudioElement>document.getElementById('switching')).play();
  }


  playReject() {
    (<HTMLAudioElement>document.getElementById('reject')).play();
  }


  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key == 'Enter') {
      if (this.isActive && this.countActive >= this.VADDurationThreshold) {
        this.sendGrpc(2, new Float32Array(), true);
        this.chunkId++;
        this.frameCount = 0;
        this.stopCountActive()
        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-danger')
        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-warning')
        document.getElementById('voiceDetectIcon').classList.remove('voice-detect-active')
        document.getElementById('voiceDetectIcon').classList.add('voice-detect-not-active')
      }
      this.isActive = false;
      event.preventDefault()
    }

  }
}
